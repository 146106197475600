import React from 'react';
import Words from './words';
import './reset.scss';
import './app.scss';

function App() {
  return (
    <div>
      <h1>These are words that I like.</h1>
      <Words />
    </div>
  );
}

export default App;
